//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'DropdownOptions',
  props: {
    addressList: {
      type: Array,
      required: true,
    },
    arrowCounter: {
      type: Number,
      required: true,
    },
  },
  methods: {
    isItemSelected(index) {
      return index === this.arrowCounter;
    },
  },
};

import { Loader } from '@googlemaps/js-api-loader';

export default class GoogleMapsService {
  constructor(bugsnag) {
    this.googleMaps = new Loader({
      apiKey: process.env.NUXT_ENV_GOOGLE_MAPS_KEY,
      version: 'weekly',
      libraries: ['places'],
    });
    this.places = null;
    this.sessionToken = null;
    this.geocoder = null;
    this.bugsnag = bugsnag;
  }

  async initialize() {
    try {
      const geocoding = await this.googleMaps.importLibrary('geocoding');
      const places = await this.googleMaps.importLibrary('places');

      this.places = new places.AutocompleteService();
      this.sessionToken = new places.AutocompleteSessionToken();
      this.geocoder = new geocoding.Geocoder();
    } catch (error) {
      this.bugsnag.notify(error);
    }
  }

  async getPlaces(searchValue) {
    const sessionToken = this.sessionToken;
    try {
      const response = await this.places.getPlacePredictions({
        input: searchValue,
        sessionToken,
        componentRestrictions: { country: ['us', 'ca', 'au', 'gb'] },
        fields: ['formatted_address'],
        types: ['address'],
      });

      return response?.predictions || [];
    } catch (error) {
      this.bugsnag.notify(error);
      return [];
    }
  }

  async getGeoCodedAddress(placeId) {
    try {
      const resultResponse = await new Promise((resolve, reject) => {
        this.geocoder.geocode({ placeId }, (results, status) => {
          // eslint-disable-next-line no-undef
          if (status === google.maps.GeocoderStatus.OK) {
            resolve(results);
          } else {
            reject(new Error(`Geocoding failed with status: ${status}`));
          }
        });
      });

      return resultResponse;
    } catch (error) {
      this.bugsnag.notify(error);

      return [];
    }
  }

  async getCountry(lat, lng) {
    try {
      const res = await this.geocoder.geocode({ location: { lat, lng } });
      const countryResult = res?.results[0]?.address_components.find((component) => component.types.includes('country'));
      return countryResult?.short_name ?? null;
    } catch (error) {
      this.bugsnag.notify(error);
      return null;
    }
  }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import debounce from 'lodash/debounce';
import { mapState } from 'vuex';
import helpers from '@/shared/store/helpers';
import FCInput from '@/shared/components/FCInput.vue';
import { getFormattedAddressObj } from '~/services/googleMaps/googleMapsHelpers';
import GoogleMapsService from '~/services/googleMaps/googleMapsService';
import DropdownOptions from './DropdownOptions.vue';

export default {
  name: 'AutoCompleteInput',
  components: {
    FCInput,
    DropdownOptions,
  },
  data() {
    return {
      autoCompleteResults: [],
      areResultsDisplayed: false,
      arrowCounter: -1,
      googleMapsService: null,
    };
  },
  computed: {
    ...mapState({
      invalidFields: state => state.checkout.invalidFields,
    }),
    ...helpers.mapInputs([
      'shipping',
      'shipping.address',
      'shipping.address2',
      'shipping.city',
      'shipping.state',
      'shipping.postalCode',
    ]),
  },
  methods: {
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.arrowCounter = -1;
        this.areResultsDisplayed = false;
      }
    },
    onArrowDown() {
      if (this.arrowCounter < this.autoCompleteResults.length - 1) {
        this.arrowCounter += 1;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
      }
    },
    onEnter() {
      const selectedResult = this.autoCompleteResults[this.arrowCounter];
      this.areResultsDisplayed = false;

      if (selectedResult) {
        this.setDeliveryFields(selectedResult.place_id);
        this.arrowCounter = -1;
      }
    },
    onChange() {
      if (this.address.length > 3) {
        this.debounce();
      }
    },
    isItemSelected(index) {
      return index === this.arrowCounter;
    },
    async setDeliveryFields(placeId) {
      this.autoCompleteResults = [];

      const geoCodeResponse = await this.googleMapsService.getGeoCodedAddress(placeId);
      const resultObj = getFormattedAddressObj(geoCodeResponse, this.$store.state.app.shippingZones);

      if (Object.keys(resultObj).length !== 0) {
        this.$store.commit('SET_FIELD', { path: 'shipping.address', value: `${resultObj.streetNumber} ${resultObj.streetAddress}` });
        this.$store.commit('SET_FIELD', { path: 'shipping.address2', value: resultObj.streetAddress2 });
        this.$store.commit('SET_FIELD', { path: 'shipping.city', value: resultObj.city });
        this.$store.commit('SET_FIELD', { path: 'shipping.state', value: resultObj.state });
        this.$store.commit('SET_FIELD', { path: 'shipping.postalCode', value: resultObj.zipCode });
        this.$store.commit('SET_FIELD', { path: 'shipping.country', value: resultObj.country });
      }
    },
    debounce: debounce(
      async function handleInput() {
        const result = await this.googleMapsService.getPlaces(this.address);

        if (Object.keys(result).length !== 0) {
          this.autoCompleteResults = result;
          this.areResultsDisplayed = true;
        } else {
          this.areResultsDisplayed = false;
        }
      },
      300,
    ),
    isInvalid(elementName) {
      return !!this.invalidFields.includes(elementName);
    },
  },
  async mounted() {
    document.addEventListener('click', this.handleClickOutside);
    this.googleMapsService = new GoogleMapsService(this.$bugsnag);
    await this.googleMapsService.initialize();
  },
  destroyed() {
    document.removeEventListener('click', this.handleClickOutside);
  },
};

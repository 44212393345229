export function findAddressData(data, detail) {
  const dataFound = data?.find(item => item.types.includes(detail));

  if (!dataFound) {
    return '';
  }

  if (detail === 'country') {
    return dataFound.short_name || '';
  }

  return dataFound.long_name || '';
}

export function getAbbrStateObj(data, shippingZones) {
  const country = findAddressData(data, 'country');
  const stateFullName = findAddressData(data, 'administrative_area_level_1');

  // TODO the state does not get set for NON-US addresses
  const abbrStateObj = shippingZones.find(zone => zone.key === country)?.provinces?.find(province => province.name === stateFullName);

  return abbrStateObj || {};
}

export function getFormattedAddressObj(addressResponse, shippingZones) {
  if (isResponseError(addressResponse)) {
    return {};
  }

  const data = addressResponse[0].address_components;
  const formattedAddressObj = {
    streetNumber: findAddressData(data, 'street_number'),
    streetAddress: findAddressData(data, 'route'),
    streetAddress2: findAddressData(data, 'subpremise'),
    city: findAddressData(data, 'locality') || findAddressData(data, 'postal_town'),
    state: getAbbrStateObj(data, shippingZones).key || '',
    zipCode: findAddressData(data, 'postal_code'),
    country: findAddressData(data, 'country'),
  };

  return formattedAddressObj;
}

function isResponseError(response) {
  return !response[0];
}
